<template>
	<main-tabs :links="links">
		<router-view></router-view>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/google-contents/uda'
					},
					label: 'Display UDA 图片模板'
				}, {
					to: {
						path: '/google-contents/category-url'
					},
					label: '品类&URL'
				}, {
					to: {
						path: '/google-contents/country-language'
					},
					label: '国家&语言'
				}]
			}
		}
	}
</script>

